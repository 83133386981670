<script>
export default {
    props: {
        open: {
            type: Boolean,
            default: false,
        },
        closedHeight: {
            type: Number,
            default: 0,
        },
    },
    watch: {
        open() {
            this.update();
        },
    },
    mounted() {
        this.update();
    },
    methods: {
        update() {
            const height = this.$refs.content.offsetHeight;
            if (this.open) {
                this.$refs.folder.style.height = height + "px";
            } else {
                this.$refs.folder.style.height = height + "px";
                setTimeout(() => {
                    this.$refs.folder.style.height = this.closedHeight + "px";
                }, 10);
            }
        },
        onTransitionEnd() {
            if (this.open) {
                this.$refs.folder.style.height = "auto";
            }
        },
    },
};
</script>

<template>
    <div ref="folder" class="foldable" @transitionend="onTransitionEnd">
        <div ref="content">
            <slot></slot>
        </div>
    </div>
</template>
