<script>
export default {
    data() {
        return {
            openMenu: -1,
        };
    },
    methods: {
        clickMenuTab: function (index) {
            if (this.openMenu == index) {
                this.openMenu = -1;
            } else {
                this.openMenu = index;
            }
        },
    },
};
</script>

<template>
    <div class="visible">
        <slot :click-menu-tab="clickMenuTab" :open-menu="openMenu"></slot>
    </div>
</template>
