<script>
export default {
    mounted() {
        import("../scripts/fancybox.js").then((importedModule) => {
            const Fancybox = importedModule.default;
            Fancybox.bind(this.$el);
        });
    },
};
</script>

<template>
    <a><slot></slot></a>
</template>
