<script>
import ajaxPost from "@/utils/ajaxPost.js";

export default {
    props: {
        offset: {
            type: Number,
            default: 0,
        },
        total: {
            type: Number,
            default: 0,
        },
        params: {
            type: Object,
            default: function () {
                return {};
            },
        },
    },
    data() {
        return {
            currentOffset: this.offset,
            list: [],
            currentTotal: this.total,
            loading: false,
        };
    },
    methods: {
        loadMore() {
            const vm = this;

            if (vm.loading) {
                return;
            }

            vm.loading = true;
            ajaxPost(
                "/ajax/content/get_products.php",
                Object.assign({ offset: this.currentOffset }, this.params, window.Application.config),
                function (response) {
                    vm.loading = false;
                    if (response && response.result && response.result == "success" && response.projects) {
                        vm.list = vm.list.concat(response.projects);
                        vm.currentOffset += response.projects.length;
                    } else {
                        vm.currentTotal = 0;
                    }
                },
                function () {
                    vm.loading = false;
                    alert("Произошла ошибка");
                }
            );
        },
    },
};
</script>

<template>
    <div class="projects-loader">
        <div class="works-tiles">
            <slot></slot>
            <div v-for="project in list" :key="project.id" class="works-tile">
                <a class="tile-inner" :href="project.link" :title="project.title">
                    <span class="image">
                        <span class="image-wrap" v-html="project.picture"> </span>
                    </span>
                    <span class="text">{{ project.title }}</span>
                </a>
                <external-link v-if="project.website" class="site" :to="'http://' + project.website" title="Перейти на сайт"
                    ><svg-icon icon="link"></svg-icon
                ></external-link>
            </div>
        </div>
        <button
            v-if="currentOffset < currentTotal"
            class="show-more-button button is-outline"
            :class="{ loading: loading }"
            @click="loadMore"
        >
            <slot name="show-more"></slot>
        </button>
    </div>
</template>
