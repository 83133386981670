<script>
export default {
    props: {
        to: {
            type: String,
            default: "",
        },
    },
};
</script>

<template>
    <a :href="to" rel="nofollow" target="_blank">
        <slot></slot>
    </a>
</template>
