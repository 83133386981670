<script>
export default {
    props: {
        whatsapp: Boolean,
    },
    computed: {
        numbersOnly() {
            return (this.$slots.default()[0].children || "").replace(/[^0-9]/g, "");
        },
        telUrl() {
            return "tel:+" + this.numbersOnly;
        },
        whatsappUrl() {
            return "https://wa.me/" + this.numbersOnly;
        },
    },
};
</script>

<template>
    <span class="phone-link">
        <a v-if="whatsapp" target="_blank" rel="nofollow" class="whatsapp" :href="whatsappUrl">
            <svg-icon icon="whatsapp" />
        </a>
        <a class="tel-link" :href="telUrl">
            <slot></slot>
        </a>
    </span>
</template>
