<script>
export default {
    inheritAttrs: false,
    props: {
        placeholder: {
            type: String,
            default: "",
        },
    },
    data() {
        return {
            text: "",
            isFocused: false,
        };
    },
    computed: {
        rootClass() {
            if (this.isFocused || (this.text && this.text.length > 0)) {
                return ["is-caption-lifted"];
            } else {
                return [];
            }
        },
    },
    methods: {
        resize() {
            const textarea = this.$refs.textarea;
            const initalScroll = window.scrollY;

            textarea.style.height = "5px";
            textarea.style.height = textarea.scrollHeight - 28 + "px";

            window.scroll(0, initalScroll);

            const formResizedEvent = new CustomEvent('formResized');
            window.dispatchEvent(formResizedEvent);
        },
        clear() {
            this.text = "";
        },
    },
    mounted() {
        window.addEventListener("clearform", () => {
            this.clear();
        });
    }
};
</script>

<template>
    <div class="animated-textarea" :class="rootClass">
        <div class="placeholder">{{ placeholder }}</div>
        <div class="input-wrap">
            <textarea
                ref="textarea"
                v-bind="$attrs"
                v-model="text"
                @focus="$data.isFocused = true"
                @blur="$data.isFocused = false"
                @input="resize"
            >
<slot></slot></textarea
            >
        </div>
    </div>
</template>
