<script>
export default {
    props: {
        num: {
            type: Number,
            default: 5,
        },
    },
    data() {
        return {
            isOpen: false,
        };
    },
};
</script>

<template>
    <ul :class="{ open: isOpen }">
        <slot></slot>
        <a v-if="!isOpen && num > 10" class="show-more" href="#" @click.prevent="isOpen = true">Показать еще</a>
    </ul>
</template>
