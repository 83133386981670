<template>
    <div class="swiper block-slider-surrogate">
        <div class="swiper-wrapper">
            <slot></slot>
        </div>
        <div class="swiper-button-prev">
            <svg-icon icon="prevArrow" />
        </div>
        <div class="swiper-button-next">
            <svg-icon icon="nextArrow" />
        </div>
    </div>
</template>
