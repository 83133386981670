<script>
import Uploader from "@/utils/uploader.js";

export default {
    props: {
        name: {
            type: String,
            default: "",
        },
        maxFiles: {
            type: Number,
            default: 10,
        },
        maxFileSize: {
            type: Number,
            default: 10485760,
        },
        types: {
            type: Array,
            default: () => [
                "application/msword",
                "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
                "application/vnd.oasis.opendocument.text",
                "application/vnd.ms-excel",
                "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
                "application/vnd.oasis.opendocument.spreadsheet",
                "application/vnd.ms-powerpoint",
                "application/vnd.openxmlformats-officedocument.presentationml.presentation",
                "application/vnd.oasis.opendocument.presentation",
                "application/pdf",
                "application/rtf",
                "text/plain",
                "image/png", 
                "image/jpeg",
                "image/bmp",
                "application/vnd.rar",
                "application/x-rar-compressed",
                "application/zip",
            ]
        }
    },
    data() {
        return {
            files: [],
            loading: false,
        };
    },
    computed: {
        typesString() {
            return this.types.join(",");
        }
    },
    methods: {
        openFileSelector() {
            this.$refs.input.click();
        },
        onFilesSelected() {
            if (this.$refs.input.files.lenngth < 1) {
                return;
            }

            for (let i = 0; i < this.$refs.input.files.length; i++) {
                const file = this.$refs.input.files[i];
                
                if (this.files.length >= this.maxFiles) {
                    alert("Максимальное количество загружаемых файлов: " + this.maxFiles);
                    break;
                }

                if (file.size > this.maxFileSize) {
                    alert(`Файл "${file.name}" слишком велик`);
                    continue;
                }

                this.addFile(file);
            }
        },
        addFile(file) {
            this.uploader.upload(file, (progress) => {
                //console.log(progress);
            }, (id) => {
                this.files.push({
                    name: file.name,
                    tmp: id
                });
            });
        },
        remove(index) {
            this.files.splice(index, 1);
        },
        clear() {
            this.files = [];
        },
    },
    mounted() {
        this.uploader = new Uploader("/ajax/files/upload_file.php", () => {
            this.loading = true;
        }, () => {
            this.loading = false;
        });

        window.addEventListener("clearform", () => {
            this.clear();
        });
    }
};
</script>

<template>
    <div class="file-uploader" :class="{ loading: loading }">
        <svg-icon ref="uploadIcon" icon="attach" v-on:click="openFileSelector"/>
        <template v-if="files.length < 1">
            <button ref="button" type="button" class="uploader-title" v-on:click="openFileSelector">
                <slot name="title"></slot>
            </button>
            <div class="uploader-description"><slot name="description"></slot></div>
        </template>
        <template v-else>
            <div class="uploader-title"><slot name="uploaded"></slot></div>
            <div v-for="(file, index) in files" :key="index" class="uploader-description uploaded">
                <span class="filename">{{ file.name }}</span>
                <button type="button" class="remove" @click="remove(index)">&times;</button>
                <input type="hidden" :name="name + '[' + index + ']'" :value="JSON.stringify({name: file.name, tmp: file.tmp})" />
            </div>
        </template>
        <div class="uploader-loading-overlay"></div>
        <input class="input" type="file" ref="input" :multiple="maxFiles > 1" :accept="typesString" v-on:input="onFilesSelected"/>
    </div>
</template>
