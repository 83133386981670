import ky from "ky";
import qs from "qs";

export default async function ajaxPost(url, data, onDone, onError) {
    try {
        const response = await ky
            .post(url, {
                timeout: 60000,
                body: qs.stringify(data),
                credentials: "include",
                headers: {
                    "content-type": "application/x-www-form-urlencoded",
                },
            })
            .json();

        onDone(response);
    } catch (err) {
      console.error(err);  
      onError(err);
    }
}
