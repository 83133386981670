import ky from "ky";
import { customAlphabet } from 'nanoid'

const chunkSize = 262144;
const idGenerator = customAlphabet('abcdefghijklmnopqrstuvwxyz1234567890', 30);

export default class {
    constructor(url, onStart, onFinish) {
        this.queue = [];
        this.state = "idle";
        this.url = url;
        this.onStart = onStart;
        this.onFinish = onFinish;
        this.current = {
            file: null,
            offset: 0,
            id: idGenerator()
        };
    }

    upload(file, onProgress, onDone) {
        this.queue.push({
            file,
            onProgress,
            onDone
        });

        if (this.state = "idle") {
            this.start();
        }
    }

    start() {
        if (this.onStart) { 
            this.onStart();
        }
        this.state = "process"
        this.process();
    }

    async process() {
        if (!this.current.file) {
            if (this.queue.length > 0) {
                const fileData = this.queue.shift();
                const fileName = fileData.file.name;
                const extension = fileName.split('.').pop();
                const tmpName = idGenerator() + "." + extension;

                this.current = {
                    file: fileData.file,
                    offset: 0,
                    id: tmpName,
                    onProgress: fileData.onProgress,
                    onDone: fileData.onDone
                };
            } else {
                this.state = "idle";
                if (this.onFinish) {
                    this.onFinish();
                    return;
                }
            }
        }

        const remainingSize = this.current.file.size - this.current.offset;
        const currentChunkSize = Math.min(remainingSize, chunkSize);
        const currentChunkEnd = this.current.offset + currentChunkSize;
        const currentChunk = this.current.file.slice(this.current.offset, currentChunkEnd);

        const formData = new FormData();
        formData.append('name', this.current.id);
        formData.append('file', currentChunk);
        
        await ky.post(this.url, {
            body: formData
        });

        this.current.offset += currentChunkSize;

        if (this.current.onProgress) {
            const onProgress = this.current.onProgress;
            onProgress(this.current.file.size / this.current.offset);
        }

        if (this.current.offset >= this.current.file.size) {
            if (this.current.onDone) {
                const onDone = this.current.onDone;
                onDone(this.current.id);
            }

            this.current = {
                file: null,
                offset: 0,
            };
        }

        setTimeout(() => {
            this.process();
        }, 10);
    }
}