export default function serializeForm(form) {
    const formData = new FormData(form);

    let data = {};
    for (let [key, value] of formData) {
        if( key.match( /([^\[]+)\[([^\]]+)\]/g ) ) {
            key.replace( /([^\[]+)\[([^\]]+)\]/g, function( $0, $1, $2 ) {
                data[$1] = data[$1] || {};
                data[$1][$2] = value;
            });
        }
        else {
            data[key] = value;
        }
    }

    return data;
}
