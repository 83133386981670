<script>
export default {
    props: {
        mapProps: {
            type: Object,
            default: function () {
                return {};
            },
        },
    },
    async mounted() {
        const maps2gis = await import('2gis-maps');
        const DG = window.DG;

        DG.then(() => {
            const coords = this.mapProps.coords.split(",").map(function (c) {
                return parseFloat(c.trim());
            });

            this.map = DG.map(this.$el, {
                center: [coords[0] - 0.0004, coords[1]],
                zoom: parseFloat(this.mapProps.zoom),
            });

            var marker = DG.marker([coords[0], coords[1]]).addTo(this.map);

            if (this.mapProps.description) {
                marker.bindPopup(this.mapProps.description);
            }
        });
    },
};
</script>

<template>
    <div class="contacts-map"></div>
</template>
