<script>
export default {
    data() {
        return {
            maximizedHeight: 0,
            isFoldable: false,
            isFolded: false,
        };
    },
    mounted() {
        const img = this.$el.getElementsByTagName("IMG")[0];

        img.addEventListener("load", () => {
            this.maximizedHeight = img.offsetHeight;
            if (this.maximizedHeight > 800) {
                this.isFoldable = true;
                this.isFolded = true;
                this.$refs.wrap.style.height = 800 + "px";
            }
        });
    },
    methods: {
        toggle() {
            if (this.isFolded) {
                this.isFolded = false;
                this.$refs.wrap.style.height = this.maximizedHeight + "px";
            } else {
                this.isFolded = true;
                this.$refs.wrap.style.height = "800px";
            }
        },
    },
};
</script>

<template>
    <div class="foldable-image">
        <div ref="wrap" class="img-wrap">
            <slot></slot>
            <div v-if="isFoldable && isFolded" class="gradient"></div>
        </div>
        <button v-if="isFoldable" class="fold-btn" :class="{ folded: isFolded }" @click="toggle">
            <svg-icon icon="arrow-down"></svg-icon>
        </button>
    </div>
</template>
