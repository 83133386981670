import { createApp, defineAsyncComponent } from "vue";

import getCoords from "@/utils/getCoords.js";
import serializeForm from "@/utils/serializeForm.js";
import ajaxPost from "@/utils/ajaxPost.js";
import BlockSliderSurrogate from "@/components/BlockSliderSurrogate.vue";
import PhoneLink from "@/components/PhoneLink.vue";
import ExternalLink from "@/components/ExternalLink.vue";
import FancyboxLink from "@/components/FancyboxLink.vue";
import MobileMenu from "@/components/MobileMenu.vue";
import FoldableBlock from "@/components/FoldableBlock.vue";
import AnimatedTextarea from "@/components/AnimatedTextarea.vue";
import ContactsMap from "@/components/ContactsMap.vue";
import FiltersBar from "@/components/FiltersBar.vue";
import LinksMore from "@/components/LinksMore.vue";
import ProjectsLoader from "@/components/ProjectsLoader.vue";
import FoldableImage from "@/components/FoldableImage.vue";
import FileUploader from "@/components/FileUploader.vue";

window.Application = window.Application || {};

if (!String.prototype.splice) {
    /**
     * {JSDoc}
     *
     * The splice() method changes the content of a string by removing a range of
     * characters and/or adding new characters.
     *
     * @this {String}
     * @param {number} start Index at which to start changing the string.
     * @param {number} delCount An integer indicating the number of old chars to remove.
     * @param {string} newSubStr The String that is spliced in.
     * @return {string} A new string with the spliced substring.
     */
    String.prototype.splice = function (start, delCount, newSubStr) {
        return this.slice(0, start) + newSubStr + this.slice(start + Math.abs(delCount));
    };
}

function init() {
    const el = document.querySelector(".vue-root");
    const template = el.innerHTML;

    const app = createApp({
        methods: {
            openMobileMenu: function () {
                document.body.classList.add("mobile-menu-open");
            },
            closeMobileMenu: function () {
                document.body.classList.remove("mobile-menu-open");
            },
        },
        template: template,
    });

    app.directive("smooth-scroll", {
        created: function (element) {
            element.addEventListener("click", function (event) {
                event.preventDefault();
                const selector = element.attributes.href.value;
                const destElement = document.querySelector(selector);
                const position = getCoords(destElement).top - 80;
                window.scrollTo({
                    top: position,
                    behavior: "smooth",
                });
            });
        },
    });

    app.directive("submit", {
        created: function (element, binding, vnode) {
            element.addEventListener("submit", function (event) {
                event.preventDefault();
                event.stopPropagation();

                var url = binding.value;
                var postParams = Object.assign({ data: serializeForm(element) }, window.Application.config);

                element.classList.add("loading");

                ajaxPost(
                    url,
                    postParams,
                    function (response) {
                        element.classList.remove("loading");
                        if (response.result && response.result == "success" && response.msg && response.msg.length > 0) {
                            const clearFormEvent = new CustomEvent('clearform');
                            window.dispatchEvent(clearFormEvent);

                            alert(response.msg);
                        } else if (response.error) {
                            alert(response.error);
                        } else {
                            alert("Произошла ошибка");
                        }
                    },
                    function () {
                        element.classList.remove("loading");
                        alert("Произошла ошибка");
                    }
                );
            });
        },
    });

    app.component(
        "AnimatedInput",
        defineAsyncComponent(() => import("./components/AnimatedInput.vue"))
    );

    app.component(
        "AnimatedTextarea",
        AnimatedTextarea
    );

    app.component(
        "BlockSlider",
        //defineAsyncComponent(() => import("./components/BlockSlider.vue"))
        defineAsyncComponent({
          loader: () => import("./components/BlockSlider.vue"),
          delay: 1,
          timeout: 60000,
          error: BlockSliderSurrogate,
          loading: BlockSliderSurrogate
        })
    );

    app.component(
        "SliderClient",
        defineAsyncComponent(() => import("./components/SliderClient.vue"))
    );

    app.component(
        "ContactsMap",
        ContactsMap
    );

    app.component(
        "ExternalLink",
        ExternalLink
    );

    app.component(
        "FancyboxLink",
        FancyboxLink
    );

    app.component(
      "FileUploader",
      FileUploader
  );

    app.component(
        "FiltersBar",
        FiltersBar
    );

    app.component(
        "FoldableBlock",
        FoldableBlock
    );

    app.component(
        "FoldableImage",
        FoldableImage
    );

    app.component(
        "LinksMore",
        LinksMore
    );

    app.component(
        "MobileMenu",
        MobileMenu
    );

    app.component(
        "PhoneLink",
        PhoneLink
    );

    app.component(
        "ProjectsLoader",
        ProjectsLoader
    );

    app.component(
        "SvgIcon",
        defineAsyncComponent(() => import("./components/SvgIcon.vue"))
    );

    app.mount(el);

    function updateCustomVh() {
        const height = window.innerHeight;
        const overlay = document.querySelector("body.mobile-menu-open .mobile-menu-overlay");
        if (overlay) {
            overlay.style.height = height + "px";
        }
    }

    function resize() {
        const border = document.querySelector(".block-landing-price .content .border");

        if (border) {
            const border = document.querySelector(".block-landing-price .content .border");
            const width = border.offsetWidth;
            const height = border.offsetHeight;

            while (border.firstChild) {
                border.removeChild(border.firstChild);
            }

            const rect = document.createElementNS("http://www.w3.org/2000/svg", "rect");
            rect.setAttribute("x", "1");
            rect.setAttribute("y", "1");
            rect.setAttribute("width", width - 2);
            rect.setAttribute("height", height - 2);
            rect.setAttribute("stroke", "#252829");
            rect.setAttribute("stroke-width", "2");
            rect.setAttribute("stroke-dasharray", "17 12");
            rect.setAttribute("fill", "none");
            border.appendChild(rect);
        }

        const footerHeight = document.querySelector(".page-footer").offsetHeight;
        document.querySelector(".page-footer-placeholder").style.height = footerHeight + "px";

        updateCustomVh();
    }

    window.addEventListener("resize", resize);
    window.addEventListener("formResized", resize);

    setTimeout(resize, 200);
    setInterval(updateCustomVh, 1000);

    let prevScrollPos = window.scrollY;

    window.addEventListener("scroll", function () {
        const scrollPos = window.scrollY;

        if (scrollPos > 120) {
            document.body.classList.add("header-fixed");
        } else {
            document.body.classList.remove("header-fixed");
        }

        if (scrollPos > prevScrollPos) {
            document.body.classList.add("scroll-bottom");
            document.body.classList.remove("scroll-top");
        } else {
            document.body.classList.remove("scroll-bottom");
            document.body.classList.add("scroll-top");
        }

        prevScrollPos = scrollPos;
    });
}

if (window.document.readyState == "loading") {
    window.document.addEventListener("DOMContentLoaded", init);
} else {
    init();
}

// Fancybox Alert
(function () {
    window.alert = function (content) {
        import("./scripts/fancybox.js").then((importedModule) => {
            const Fancybox = importedModule.default;

            Fancybox.close(true);
            Fancybox.show([
                {
                    src: '<div class="fancybox-alert">' + content + "</div>",
                    type: "html",
                },
            ]);
        });
    };
})();
