<script>
export default {
    props: {
        initalOpenItems: {
            type: Array,
            default: function () {
                return [];
            },
        },
    },
    data() {
        return {
            openItems: this.initalOpenItems,
        };
    },
    methods: {
        isItemOpen(id) {
            return this.openItems.includes(id);
        },
        toogleItem(id) {
            if (this.isItemOpen(id)) {
                this.openItems = this.openItems.filter((item) => {
                    return item !== id;
                });
            } else {
                this.openItems = [...this.openItems, id];
            }
        },
        closeMobileMenu() {
            this.$root.closeMobileMenu();
        },
    },
};
</script>

<template>
    <div class="mobile-menu-overlay" @click="closeMobileMenu">
        <slot :is-item-open="isItemOpen" :toogle-item="toogleItem"></slot>
    </div>
</template>
